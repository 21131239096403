<template>
  <div class='auth-wrapper auth-v2'>
    <div class='auth-inner'>
      <!-- brand logo -->

      <!--/ brand logo -->

      <v-row class='auth-row ma-0'>
        <v-col
            lg='8'
            class='d-none d-lg-block position-relative overflow-hidden pa-0'
        >
          <div class='auth-illustrator-wrapper'>
            <!-- triangle bg -->
            <img
                height='362'
                class='auth-mask-bg'
                :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
            />

            <!-- tree -->
            <v-img
                height='226'
                width='300'
                class='auth-tree'
                src='@/assets/images/misc/tree-4.png'
            ></v-img>

            <!-- 3d character -->
            <div class='d-flex align-center h-full pa-16 pe-0'>
              <v-img
                  contain
                  max-width='100%'
                  height='692'
                  class='auth-3d-group'
                  :src="require(`@/assets/images/3d-characters/cover.png`)"
              ></v-img>

            </div>
          </div>
        </v-col>

        <v-col
            lg='4'
            class='d-flex align-center auth-bg pa-10 pb-0'
        >

          <v-row>
            <v-col
                cols='12'
                sm='8'
                md='6'
                lg='12'
                class='mx-auto'
            >
              <v-card flat>

                <h1 class='text-center mb-16'>
                  ONLINE STORE MANAGEMENT SYSTEM
                </h1>
                <v-card-text>
                  <p class='text-2xl text-center font-weight-semibold text--primary mb-2'>
                    เข้าสู่ระบบหลังบ้าน 👋🏻
                  </p>
                  <p class='mb-2  text-center'>
                    กรุณากรอกข้อมูลให้ถูกต้อง
                  </p>
                </v-card-text>

                <!--                <v-card-text>-->
                <!--                  <v-alert-->
                <!--                    text-->
                <!--                    color='primary'-->
                <!--                  >-->
                <!--                    <small class='d-block mb-1'>-->
                <!--                      Admin Email: <strong>admin@materio.com</strong> / Pass: <strong>admin</strong>-->
                <!--                    </small>-->
                <!--                    <small>-->
                <!--                      Client Email: <strong>client@materio.com</strong> / Pass:-->
                <!--                      <strong>client</strong>-->
                <!--                    </small>-->
                <!--                  </v-alert>-->
                <!--                </v-card-text>-->
                <!-- login form -->

                <v-card-text>
                  <v-alert type='error' v-if='!isLoggedIn' text color='error'> {{ message }}
                  </v-alert>
                </v-card-text>
<!--                <v-card-text>-->
<!--                  <v-alert-->
<!--                      text-->
<!--                      color="primary"-->
<!--                  >-->
<!--                    <small>-->
<!--                      Administrator : <strong>admin</strong> / Pass: <strong>1234</strong>-->
<!--                    </small>-->
<!--                    <br>-->
<!--                    <small>-->
<!--                      Center : <strong>center</strong> / Pass: <strong>111111</strong>-->
<!--                    </small>-->
<!--                    <br>-->
<!--                    <small>-->
<!--                      Manager : <strong>manager</strong> / Pass: <strong>111111</strong>-->
<!--                    </small>-->
<!--                    <br>-->
<!--                    <small>-->
<!--                      Employee : <strong>user</strong> / Pass: <strong>111111</strong>-->
<!--                    </small>-->
<!--                  </v-alert>-->
<!--                </v-card-text>-->
                <v-card-text>
                  <v-form
                      ref='loginForm'
                      @submit.prevent='handleFormSubmit'
                  >
                    <v-text-field
                        v-model.trim='username'
                        outlined
                        label='Username'
                        placeholder='username'
                        :rules='[validators.required]'
                        hide-details='auto'
                        class='mb-6'
                    ></v-text-field>

                    <v-text-field
                        v-model.trim='password'
                        outlined
                        label='Password'
                        placeholder='Password'
                        :type="isPasswordVisible ? 'text' : 'password'"
                        :append-icon='isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline'
                        @click:append='isPasswordVisible = !isPasswordVisible'
                        :rules='[validators.required]'
                        hide-details='auto'
                        class='mb-2'
                    ></v-text-field>

                    <v-btn
                        block
                        :loading='loading'
                        color='primary'
                        type='submit'
                        class='mt-6'
                    >
                      เข้าสู่ระบบ
                    </v-btn>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {mdiEyeOffOutline, mdiEyeOutline, mdiAlert} from '@mdi/js'
import {ref} from '@vue/composition-api'
import {required, emailValidator} from '@core/utils/validation'
import {api} from '@/services/api'
import {useRouter} from '@core/utils'
import themeConfig from '@themeConfig'
import axios from '@axios'
import store from '@/store'
import user from "@/navigation/vertical/user";

export default {
  setup() {
    // Template Ref
    const loginForm = ref(null)
    const {router} = useRouter()
    const isPasswordVisible = ref(false)
    const username = ref('')
    const password = ref('')
    const loading = ref(false)
    const isLoggedIn = ref(true)
    const message = ref('')
    const handleFormSubmit = () => {
      const isFormValid = loginForm.value.validate()
      if (!isFormValid) return
      loading.value = true
      api
          .post({
            path: '/login',
            body: {
              username: username.value,
              password: password.value,
            },
          })
          .then(res => {

            isLoggedIn.value = res.response
            if (res.response) {
              localStorage.setItem('token', res.token)
              axios.defaults.headers.common.Authorization = res.token
              getMe(res.token)
            } else {
              message.value = res.message
              loading.value = false
            }
          })
          .catch(error => {
            loading.value = false
            console.error('Oops, Unable to login!')
            console.log('error :>> ', error.response)
          })
    }

    const getCurrenCies = async () => {
      api.get({
        path: '/currencys',
        param: `?search=&active=1`,
      }).then(res => {
        localStorage.setItem('currency', res.data[0].currency_code)
        localStorage.setItem('currencyId', res.data[0].currency_id)
        getMenu()

      }).catch(err => {
        console.log(err)
      })
    }
    const getMenu = () => {
      api.get({
        path: '/menuShow',
      }).then(({path}) => {
        localStorage.setItem('menu', JSON.stringify(path))
        window.location.reload()
      }).catch(err => {
        console.log(err)
      })
    }

    const getMe = (token) => {
      api
          .get({
            path: '/profile',
          })
          .then(res => {
            if (res.response) {
              localStorage.setItem('userData', JSON.stringify(res.data))
              localStorage.setItem('userRole', res.data.role_name)
              store.commit('app/setRoleName', res.data.role_name)
              getCurrenCies()
            } else {
              message.value = res.message
              loading.value = false
            }
          })
          .catch(error => {
            console.log('error : ', error)
          })
    }

    return {
      handleFormSubmit,
      isPasswordVisible,
      username,
      password,
      loading,
      validators: {
        required,
        emailValidator,
      },
      // themeConfig
      message,
      appName: themeConfig.app.name,
      isLoggedIn,
      appLogo: themeConfig.app.logo,
      icons: {
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiAlert,
      },

      // Template Refs
      loginForm,
    }
  },
}
</script>

<style lang='scss' scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
